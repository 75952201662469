import {ACTION_TYPE} from '../utils/index.js';

const UserState = {
  users: {},
};

export default (state = UserState, action) => {
  if (action.type === ACTION_TYPE.READ_USERS) {
    return {
      ...state,
      users: action.payload.users.reduce((oldUsers, user) => (
        {...oldUsers, [user.id]: user,
        }), {}),
    };
  } else if ([ACTION_TYPE.UPDATE_USER, ACTION_TYPE.SIGN_IN, ACTION_TYPE.SIGN_UP].includes(action.type)) {
    const userId = action.payload.user.id;
    return {
      ...state,
      users: {
        ...state.users,
        [userId]: {...state.users[userId], ...action.payload.user},
      },
    };
  } else if (action.type === ACTION_TYPE.READ_USER) {
    const {user} = action.payload;
    const userId = user.id;
    return {
      ...state,
      users: {
        ...state.users,
        [userId]: {
          ...state.users[userId],
          ...user,
        },
      },
    };
  } else if (action.type === ACTION_TYPE.SIGN_OUT) {
    // FIX ME?
    return UserState;
  }
  return state;
};
