import React from 'react';

function Contact() {
  return (
    <>
      <h2>Contact</h2>
    </>
  );
}

export default Contact;
