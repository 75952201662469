import React from 'react';
import { Avatar, Box, Icon, Heading, Text } from '@chakra-ui/react';
import { ReactComponent as FbIcon } from '../assets/icons/fb.svg';
import { ReactComponent as MessengerIcon } from '../assets/icons/messenger.svg';
import { ReactComponent as GoogleIcon } from '../assets/icons/google.svg';
import { ReactComponent as PinIcon } from '../assets/icons/pin.svg';
import { ReactComponent as MailIcon } from '../assets/icons/mail.svg';
import { ReactComponent as PhoneIcon } from '../assets/icons/phone.svg';
import { useTranslation } from 'react-i18next';

const socialIcons = [FbIcon, MessengerIcon, GoogleIcon];
const contactDetails = [
  {
    name: 'address-txt',
    Icon: PinIcon,
  },
  {
    name: 'email-address-txt',
    Icon: MailIcon,
  },
  {
    name: 'phone-number-txt',
    Icon: PhoneIcon,
  },
];

const quickLinks = [
  {
    routeName: 'news-tlt',
    address: '/',
  },
  {
    routeName: 'calendar-tlt',
    address: '/',
  },
  {
    routeName: 'careers-tlt',
    address: '/',
  },
  {
    routeName: 'privacy-policy-tlt',
    address: '/',
  },
  {
    routeName: 'policies-and-raports-tlt',
    address: '/',
  },
];

function Footer() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        height: 'auto',
        backgroundColor: '#1E2644',
        display: 'flex',
        flexDirection: 'row',
        py: 5,
        px: 24,
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          <Avatar
            name='Remy Sharp'
            src='https://i.imgur.com/Tmw6eFo.png'
            justifySelf='center'
          />
          <Heading
            variant='h6'
            component='a'
            href='/'
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'Lora, serif',
              fontWeight: 400,
              color: 'white',
              lineHeight: 1.2,
              textTransform: 'uppercase',
              fontSize: '17px',
              textDecoration: 'none',
              width: '200px',
            }}
          >
            Școala Gimnazială Mihail Sadoveanu
          </Heading>
        </Box>
        {/* <Box sx={{ mr: 'auto' }}>
          {socialIcons.map((Component, index) => (
            <Icon
              as={Component}
              key={`social-${index}`}
              sx={{ color: 'white', height: 30, width: 30, pr: 2 }}
            ></Icon>
          ))}
        </Box> */}
      </Box>
      <Box>
        <Text
          sx={{
            mt: 2,
            fontFamily: 'Raleway, sans-serif',
            fontWeight: 400,
            color: 'white',
            fontSize: '16px',
            textDecoration: 'none',
          }}
        >
          {t('landing-description')}
        </Text>
        <Box sx={{ mt: 4 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '10px',
                mt: 3,
                maxWidth: '250px',
              }}
            >
              <Text
                sx={{
                  mb: 1,
                  fontFamily: 'Raleway, sans-serif',
                  fontWeight: 500,
                  color: 'white',
                  fontSize: '18px',
                  textDecoration: 'none',
                }}
              >
                {t('contact-tlt')}
              </Text>
              {contactDetails.map((detail) => (
                <Box
                  key={`contact-details-${detail.name}`}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Icon
                    as={detail.icon}
                    sx={{ color: 'white', height: 15, width: 15, pr: 1 }}
                  ></Icon>
                  <Text
                    sx={{
                      fontFamily: 'Raleway, sans-serif',
                      fontWeight: 500,
                      color: 'white',
                      fontSize: '14px',
                      textDecoration: 'none',
                      textAlign: 'left',
                    }}
                  >
                    {t(detail.name)}
                  </Text>
                </Box>
              ))}
            </Box>
            <Box>
              <Text
                sx={{
                  mt: 3,
                  fontFamily: 'Raleway, sans-serif',
                  fontWeight: 500,
                  color: 'white',
                  fontSize: '18px',
                  textDecoration: 'none',
                  textAlign: 'left',
                }}
              >
                {t('quicklinks-tlt')}
              </Text>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  mt: 1,
                }}
              >
                {quickLinks.map((link) => (
                  <Text
                    key={`link-${link.routeName}`}
                    component='a'
                    href={link.address}
                    sx={{
                      mt: 1,
                      fontFamily: 'Raleway, sans-serif',
                      fontWeight: 500,
                      color: '#a8d0e6',
                      fontSize: '14px',
                      textDecoration: 'none',
                      textAlign: 'left',
                    }}
                  >
                    {t(link.routeName)}
                  </Text>
                ))}
              </Box>
            </Box>
          </Box>
          <Text
            sx={{
              mt: 6,
              fontFamily: 'Raleway, sans-serif',
              color: 'white',
              fontSize: '14px',
              fontWeight: 500,
              height: '30px',
              textDecoration: 'none',
              textAlign: 'left',
            }}
          >
            {t('copyrights-txt')} {t('app-name')}
          </Text>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
