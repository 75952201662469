import React from 'react';
import {Link} from 'react-router-dom';
import ROUTES from '../utils/routes';

function About() {
  return (
    <>

      <h2>Welcome to the about page!</h2>
      <Link to={ROUTES.HOME}>Home</Link>
    </>
  );
}

export default About;
