const ACTION_TYPE = {
  SIGN_UP: 'SIGN_UP',
  SIGN_IN: 'SIGN_IN',
  AUTH_ERROR: 'AUTH_ERROR',
  SIGN_OUT: 'SIGN_OUT',
  READ_USERS: 'READ_USERS',
  UPDATE_USER: 'UPDATE_USER',
  CREATE_USER: 'CREATE_USER',
  READ_USER: 'READ_USER',
};

export {
  ACTION_TYPE,
};
