import React from 'react';
import { Avatar, Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

function UnderConstruction() {
  const { t } = useTranslation();
  return (
    <div
      style={{
        height: '60vh',
        backgroundColor: '#bdbdbd',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Avatar name='Remy Sharp' src='https://i.imgur.com/Tmw6eFo.png' />
      <Heading as='h3' size='lg' mt='2' color='white'>
        {t('under-construction-txt')}
      </Heading>
    </div>
  );
}

export default UnderConstruction;
