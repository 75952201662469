import React from 'react';

function Programs() {
  return (
    <>
      <h2>Programs</h2>
    </>
  );
}

export default Programs;
