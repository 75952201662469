import React from 'react';

function History() {
  return (
    <>
      <h2>History</h2>
    </>
  );
}

export default History;
