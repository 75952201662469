const firebaseConfig = {
  apiKey: 'AIzaSyBr1qtWGD_aERgV8F3JE9nqSEc7OEisYr4',
  authDomain: 'msadoveanu-71a65.firebaseapp.com',
  projectId: 'msadoveanu-71a65',
  storageBucket: 'msadoveanu-71a65.appspot.com',
  messagingSenderId: '714863166023',
  appId: '1:714863166023:web:811e8adce2ead4f8d8a762',
  measurementId: 'G-4GBVPY08P7',
};

export {
  firebaseConfig,
};
