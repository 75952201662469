import React from 'react';

function Activities() {
  return (
    <>
      <h2>Extra Curricular Activities</h2>
    </>
  );
}

export default Activities;
