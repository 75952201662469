const ROUTES = {
  ABOUT: '/about',
  ACTIVITIES: '/activities',
  CONTACT: '/contact',
  CURRICULUM: '/curriculum',
  HISTORY: '/history',
  HOME: '/',
  NEWSROOM: '/newsroom',
  PROGRAMS: '/programs',
  SIGN_IN: '/signin',
  SIGN_UP: '/signup',
};

export default ROUTES;
