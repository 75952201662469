import React from 'react';
import { Box, Badge, Image } from '@chakra-ui/react';

export function NewsItem({ news }) {
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };
  return (
    <Box
      sx={{ cursor: 'pointer' }}
      maxW='sm'
      borderWidth='1px'
      borderRadius='lg'
      overflow='hidden'
      onClick={() => openInNewTab(news.link)}
    >
      <Image src={news.imageUrl} alt={news.title} />

      <Box p='6'>
        <Box display='flex' alignItems='baseline'>
          <Badge borderRadius='full' px='2' colorScheme='teal'>
            Nou
          </Badge>
          <Box
            color='gray.500'
            fontWeight='semibold'
            letterSpacing='wide'
            fontSize='xs'
            textTransform='uppercase'
            ml='2'
          >
            {news.date}
          </Box>
        </Box>

        <Box
          mt='1'
          fontWeight='semibold'
          as='h4'
          color={'gray.700'}
          lineHeight='tight'
          noOfLines={1}
        >
          {news.title}
        </Box>

        <Box display='flex' mt='2' alignItems='center'>
          <Box as='span' color='gray.600' fontSize='sm'>
            {news.description}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
