import React from 'react';
// import {Link} from 'react-router-dom';
// import ROUTES from '../utils/routes';
// import {getPosts, db} from '../services/firebase.js';
import { Box, Button, Flex, Link, Text, Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Landing from '../organisms/Landing';
// import BannerItem from '../organisms/BannerItem';
import UnderConstruction from '../molecules/UnderConstruction';
import ROUTES from '../utils/routes';
import { useNavigate } from 'react-router-dom';

function Home() {
  // const onClick = async () => {
  //   const result = await getPosts(db);
  //   console.log('RESULT', result);
  // };
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Box>
      <Landing />
      <Box
        sx={{
          height: '100vh',
          color: '#edeeef',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Heading
          sx={{
            mb: 1,
            fontFamily: 'Dancing Script, cursive',
            fontWeight: 500,
            width: '800px',
            fontSize: '38px',
            // color: '#212121',
            color: '#1E2644',
          }}
        >
          Bine ați venit!
        </Heading>
        <Text
          sx={{
            mb: 1,
            fontFamily: 'Raleway, sans-serif',
            fontWeight: 500,
            fontSize: '17px',
            lineHeight: 1.8,
            width: '800px',
            fontSize: '18px',
            color: '#212121',
          }}
        >
          {t('school-description')}
        </Text>
        <Flex
          direction='row'
          justifyContent='center'
          mt={8}
          gap={6}
          flexWrap={'wrap'}
          maxW='700px'
        >
          <Button
            borderRadius='full'
            colorScheme='blue'
            fontWeight={400}
            fontFamily='Raleway, sans-serif'
          >
            <Link
              sx={{ textDecoration: 'none' }}
              href='https://docs.google.com/spreadsheets/d/1g7jEAKKnLHfas-7J5ZOq7N3prvFs-Qba/edit?usp=sharing&ouid=103025297139894326744&rtpof=true&sd=true'
            >
              {t('pregatitoare-plan-txt')}
            </Link>
          </Button>
          <Button
            borderRadius='full'
            colorScheme='blue'
            fontWeight={400}
            fontFamily='Raleway, sans-serif'
          >
            <Link href='https://docs.google.com/document/d/1mvjK_d1qgISquWEs11gAl7y7-7Tq3xIO/edit?usp=sharing&ouid=103025297139894326744&rtpof=true&sd=true'>
              {t('pregatitoare-departajare-txt')}
            </Link>
          </Button>
          <Button
            borderRadius='full'
            colorScheme='blue'
            fontWeight={400}
            fontFamily='Raleway, sans-serif'
            onClick={() => navigate(ROUTES.NEWSROOM)}
          >
            {t('news-tlt')}
          </Button>
        </Flex>
      </Box>
      {/* <BannerItem/> */}
      <UnderConstruction />
    </Box>
  );
}

export default Home;
