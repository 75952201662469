import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut as firebaseSignOut,
} from 'firebase/auth';
import {ACTION_TYPE} from '../utils/index.js';
import {auth, googleProvider} from '../../services/index.js';
import {doc, setDoc} from 'firebase/firestore';

/**
 * Sign up the user
 *
 * @param {string} username the user's name
 * @param {string} email the user's email
 * @param {string} password the user's password
 * @return {function}
 */
const signUp = ( username, email, password) =>
  async (dispatch) => {
    createUserWithEmailAndPassword(
        auth,
        email,
        password,
    ).then((user) => {
      dispatch({
        type: ACTION_TYPE.SIGN_UP,
        payload: user.user,
      });
      dispatch(addUser({email: user.user.email, username, uid: user.user.uid}));
    }).catch((e) => {
      dispatch({
        type: ACTION_TYPE.AUTH_ERROR,
        payload: 'Something went wrong, we couldn\'t create your account. Please try again.',
      });
    });
  };

/**
 * Sign in the user
 *
 * @param {string} email the user's email
 * @param {string} password the user's password
 * @return {function}
 */
const signIn = ( email, password) =>
  (dispatch) => {
    signInWithEmailAndPassword(
        auth,
        email,
        password,
    ).then((userCredential) => {
      const user = userCredential.user;
      console.log(user);
      dispatch({
        type: ACTION_TYPE.SIGN_IN,
        payload: user,
      });
    })
        .catch((error) => {
          dispatch({
            type: ACTION_TYPE.AUTH_ERROR,
            payload: 'Something went wrong, we couldn\'t create your account. Please try again.',
          });
        });
  };

/**
 * Sign out a user
 *
 * @return {function}
 */
const signOut = () => async (dispatch) => {
  firebaseSignOut(auth).then(() => {
    dispatch({
      type: ACTION_TYPE.SIGN_OUT,
      payload: '',
    });
  });
};
/**
 * Sign up a user with Google
 *
 * @return {function}
 */
const signUpWithGoogle = () => (dispatch) => {
  signInWithPopup(auth, googleProvider)
      .then((result) => {
        console.log(result);
        dispatch(addUser({
          email: result.user.email,
          username: result.user.displayName,
          uid: result.user.uid,
        }));
      });
};

/**
 * Sign in with Google
 *
 * @return {function}
 */
const signInWithGoogle = () => (dispatch) => {
  signInWithPopup(auth, googleProvider)
      .then((result) => {
        dispatch(signInSuccess(result));
      });
};


const verifyAuth = () => (dispatch) => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      dispatch(signInSuccess(user));
    }
  });
};

/**
 * Sign in with success
 *
 * @param {object} payload the action's payload
 * @return {function}
 */
const signInSuccess = (payload) => {
  return {
    type: ACTION_TYPE.SIGN_IN,
    payload: payload.user,
  };
};

/**
 * An utilitary function to add a user to the database
 *
 * @param {any} user The user object we want to store
 * @return {Promise}
 */
const addUser = async (user) => {
  await setDoc(doc(db, 'users', user.uid), {
    username: user.username,
    email: user.email,
  });
};

export {
  signUp,
  signIn,
  signInWithGoogle,
  signUpWithGoogle,
  signOut,
  verifyAuth,
};
