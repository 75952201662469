import React from 'react';

function Curriculum() {
  return (
    <>
      <h2>Curriculum</h2>
    </>
  );
}

export default Curriculum;
