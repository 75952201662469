import React from 'react';
import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import './i18n/config';
import ROUTES from './utils/routes';
import About from './pages/About';
import Activities from './pages/Activities';
import Contact from './pages/Contact';
import Curriculum from './pages/Curriculum';
import History from './pages/History';
import Home from './pages/Home';
import NewsRoom from './pages/NewsRoom';
import Programs from './pages/Programs';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Navbar from './organisms/Navbar';
import Footer from './organisms/Footer';

function App() {
  return (
    <div className='App'>
      <Navbar />
      <Routes>
        <Route path={ROUTES.HOME} element={<Home />} />
        <Route path={ROUTES.ABOUT} element={<About />} />
        <Route path={ROUTES.ACTIVITIES} element={<Activities />} />
        <Route path={ROUTES.CONTACT} element={<Contact />} />
        <Route path={ROUTES.CURRICULUM} element={<Curriculum />} />
        <Route path={ROUTES.HISTORY} element={<History />} />
        <Route path={ROUTES.NEWSROOM} element={<NewsRoom />} />
        <Route path={ROUTES.PROGRAMS} element={<Programs />} />
        <Route path={ROUTES.SIGN_IN} element={<SignIn />} />
        <Route path={ROUTES.SIGN_UP} element={<SignUp />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
