import React from 'react';
import { Box, Heading, Flex } from '@chakra-ui/react';
import { NewsItem } from '../organisms/NewsItem';

const news = [
  {
    title: 'Anunț pentru concurs de ocupare posturi',
    description:
      'Datorită faptului că până la data de 15.09.2023, data limită de depunere...',
    date: '9/21/2023',
    link: 'https://drive.google.com/file/d/1UIznjQaVPdA89MSp6g-mtXBX8pidb7xd/view?usp=sharing',
    imageUrl:
      'https://images.unsplash.com/photo-1606636660488-16a8646f012c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80',
  },
];

function NewsRoom() {
  return (
    <Box
      sx={{
        height: '100vh',
        color: '#edeeef',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Heading
        sx={{
          mb: 12,
          fontFamily: 'Dancing Script, cursive',
          fontWeight: 500,
          width: '800px',
          fontSize: '38px',
          // color: '#212121',
          color: '#1E2644',
        }}
      >
        Noutăți
      </Heading>
      <Flex direction='row' gap='5'>
        {news.map((newsItem, index) => (
          <NewsItem key={`newsitem-${index}`} news={newsItem} />
        ))}
      </Flex>
    </Box>
  );
}

export default NewsRoom;
