import React from 'react';
import {
  Avatar,
  Box,
  Collapse,
  Flex,
  Heading,
  IconButton,
  Text,
  Button,
  Menu,
  MenuItem,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ROUTES from '../utils/routes';
const pages = [
  'about-us-tlt',
  'academic-tlt',
  'outside-the-classroom-tlt',
  'news-tlt',
  'contact-tlt',
];

const navLinks = {
  'news-tlt': ROUTES.NEWSROOM,
  'about-us-tlt': '',
  'academic-tlt': '',
  'outside-the-classroom-tlt': '',
  'contact-tlt': '',
};

const shortcuts = ['portals-tlt', 'giving-tlt'];

function Navbar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [solid, setSolid] = useState(true);

  useEffect(() => {
    console.log(location.pathname);
    if (location.pathname !== ROUTES.HOME) {
      console.log('here');
      setSolid(false);
    }
  }, [location]);

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const movableNavbar = solid && scrollPosition < window.innerHeight;
  console.log(solid, movableNavbar);
  // movableNavbar => false cand e mai mare si daca e pe alta pagina decat root
  return (
    <>
      <Flex
        width='100%'
        height='auto'
        direction='row'
        position='fixed'
        color='transparent'
        sx={{
          backgroundColor: movableNavbar ? 'transparent' : 'white',
        }}
        boxShadow={movableNavbar ? '' : 'base'}
      >
        <Flex
          width='100%'
          direction='row'
          sx={{
            px: 4,
            pt: 2,
            maxWidth: '100%',
            backgroundImage: 'none !important',
            backgroundColor: 'transparent',
          }}
        >
          <Flex
            direction='row'
            justifyContent='center'
            alignItems='center'
            px={12}
            pl={5}
          >
            <Avatar name='Remy Sharp' src='https://i.imgur.com/Tmw6eFo.png' />
            <Heading
              variant='h6'
              onClick={() => navigate(ROUTES.HOME)}
              sx={{
                mr: 2,
                cursor: 'pointer',
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'Lora, serif',
                fontWeight: 400,
                color: movableNavbar ? 'white' : '#212121',
                lineHeight: 1.2,
                textTransform: 'uppercase',
                fontSize: movableNavbar ? '17px' : '16px',
                textDecoration: 'none',
                width: '200px',
              }}
            >
              Școala Gimnazială Mihail Sadoveanu
            </Heading>
          </Flex>

          {/* <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
              color='inherit'
            >
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Text textAlign='center'>{page}</Text>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}
          {/* <Heading
            variant='h6'
            noWrap
            component='a'
            href=''
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontSize: '16px',
              fontFamily: 'Lora, serif',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Școala Gimnazială Mihail Sadoveanu
          </Heading> */}
          <Box
            sx={{
              flexGrow: 1,
              justifyContent: 'flex-end',
              display: { xs: 'none', md: 'flex' },
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            <Collapse in={movableNavbar}>
              <Box
                justifyContent='flex-end'
                sx={{ display: 'flex', flexDirection: 'row', mt: 1 }}
              >
                {shortcuts.map((shortcut) => (
                  <Button
                    key={shortcut}
                    onClick={handleCloseNavMenu}
                    color={!movableNavbar ? 'black' : 'white'}
                    colorScheme={!movableNavbar ? 'blackAlpha' : 'whiteAlpha'}
                    variant='ghost'
                    sx={{
                      fontFamily: 'Raleway, sans-serif',
                      textTransform: 'none',
                      fontSize: '12px',
                    }}
                  >
                    {t(shortcut)}
                  </Button>
                ))}
              </Box>
            </Collapse>
            <Box
              justifyContent='flex-end'
              sx={{ display: 'flex', flexDirection: 'row' }}
            >
              {pages.map((page) => (
                <Button
                  key={page}
                  onClick={() => navigate(navLinks[page])}
                  color={!movableNavbar ? 'black' : 'white'}
                  colorScheme={!movableNavbar ? 'blackAlpha' : 'whiteAlpha'}
                  variant='ghost'
                  sx={{
                    display: 'block',
                    fontFamily: 'Raleway, sans-serif',
                    textTransform: 'none',
                    fontSize: '14px',
                  }}
                >
                  {t(page)}
                </Button>
              ))}
            </Box>
          </Box>
        </Flex>
      </Flex>
    </>
  );
}

export default Navbar;
