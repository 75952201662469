import {ACTION_TYPE} from '../utils/index.js';

export const AuthState = {
  authenticated: false,
  userId: undefined,
};

const authReducer = (state = AuthState, action) => {
  if ([ACTION_TYPE.SIGN_IN, ACTION_TYPE.SIGN_UP].includes(action.type)) {
    return {
      authenticated: true,
      userId: action.payload.user?.id,
    };
  }
  if (action.type === ACTION_TYPE.SIGN_OUT) {
    return {
      authenticated: false,
      userId: undefined,
    };
  }
  return state;
};

export default authReducer;
