import React from 'react';
import { Text, Avatar, Box, Icon } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ThinLongDownIcon } from '../assets/icons/thin-long-down.svg';

function Landing() {
  const { t } = useTranslation();
  return (
    <div
      style={{
        height: '100vh',
        backgroundColor: '#1E2644',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          marginRight: '204px',
          marginTop: '100px',
          justifyContent: 'center',
          flexGrow: 1,
          alignSelf: 'flex-end',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Text
          variant='h4'
          sx={{
            mt: 2,
            fontFamily: 'Dancing Script, sans-serif',
            color: 'white',
            fontSize: '38px',
            textDecoration: 'none',
            width: '500px',
            textAlign: 'left',
            mb: 3,
          }}
        >
          {t('catchphrase-txt')}
        </Text>
        <Text
          variant='h4'
          sx={{
            mt: 2,
            fontFamily: 'Raleway, sans-serif',
            fontWeight: 300,
            color: 'white',
            lineHeight: 2,
            fontSize: '20px',
            textDecoration: 'none',
            width: '500px',
            textAlign: 'left',
          }}
        >
          {t('landing-description')}
        </Text>
      </div>
      <Box>
        <Text
          sx={{
            mb: 1,
            fontFamily: 'Raleway, sans-serif',
            fontWeight: 300,
            color: 'white',
            fontSize: '12px',
            textDecoration: 'none',
          }}
        >
          {t('explore-txt')}
        </Text>
        <Icon
          as={ThinLongDownIcon}
          sx={{ color: 'white', fill: 'white' }}
          boxSize={6}
        />
      </Box>
    </div>
  );
}

export default Landing;
