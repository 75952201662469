import React from 'react';

function SignUp() {
  return (
    <>
      <h2>SignUp</h2>
    </>
  );
}

export default SignUp;
